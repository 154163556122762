define("ember-ajax/-private/utils/parse-response-headers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CRLF = void 0;
  _exports.default = parseResponseHeaders;
  const CRLF = '\u000d\u000a';
  _exports.CRLF = CRLF;
  function parseResponseHeaders(headersString) {
    const headers = {};
    if (!headersString) {
      return headers;
    }
    return headersString.split(CRLF).reduce((hash, header) => {
      let [field, ...value] = header.split(':');
      field = field.trim();
      const valueString = value.join(':').trim();
      if (valueString) {
        hash[field] = valueString;
      }
      return hash;
    }, headers);
  }
});
define("ember-ajax/mixins/ajax-support", ["exports", "@ember/object/mixin", "@ember/service", "@ember/object", "@ember/object/computed"], function (_exports, _mixin, _service, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _mixin.default.create({
    /**
     * The AJAX service to send requests through
     *
     * @property {AjaxService} ajaxService
     * @public
     */
    ajaxService: (0, _service.inject)('ajax'),
    /**
     * @property {string} host
     * @public
     */
    host: (0, _computed.alias)('ajaxService.host'),
    /**
     * @property {string} namespace
     * @public
     */
    namespace: (0, _computed.alias)('ajaxService.namespace'),
    /**
     * @property {object} headers
     * @public
     */
    headers: (0, _computed.alias)('ajaxService.headers'),
    ajax(url, _method, _options) {
      // @ts-ignore
      const augmentedOptions = this.ajaxOptions(...arguments);
      return (0, _object.get)(this, 'ajaxService').request(url, augmentedOptions);
    }
  });
  _exports.default = _default;
});